<template>
    <div class="calls-screen">
        <div class="calls-screen-title">
            <h3>קריאות שירות - ({{filters_data.length}})</h3>
        </div>
        <div class="calls-screen-filters">
            
            <div class="input">
                <label>
                    מתאריך:
                    <el-input v-model="filters.selected_from_date" size="small" type="date" placeholder="מתאריך" />
                </label>
            </div>
            <div class="input">
                <label>
                    עד תאריך:
                    <el-input v-model="filters.selected_to_date" size="small" type="date" placeholder="מתאריך" />
                </label>
            </div>
            <div class="input">
                <label>
                    סניף:
                    <el-select
                        :disabled="role < 99"
                        filterable
                        clearable
                        v-model="filters.selected_branche"
                        class="m-2"
                        placeholder="סניף"
                        size="small"
                        style="width: 100%;"
                    >
                        <el-option
                            v-for="item in branches_options"
                            :key="item"
                            :value="item"
                        />
                    </el-select>
                </label>
            </div>
            <div class="input">
                <label>
                    סוג תקלה:
                    <el-select
                        clearable
                        v-model="filters.selected_error"
                        class="m-2"
                        placeholder="סוג תקלה"
                        size="small"
                        style="width: 100%;"
                        >
                            <el-option
                            v-for="err in errors_options"
                            :key="err"
                            :value="err"
                            />
                    </el-select>
                </label>
            </div>
            <div class="input">
                <label>
                     סטטוס:
                    <el-select
                        clearable
                        v-model="filters.selected_status"
                        class="m-2"
                        placeholder="סוג תקלה"
                        size="small"
                        style="width: 100%;"
                        >
                            <el-option
                            v-for="status in ['הכל','פתוח','בטיפול','בוטל','סגור']"
                            :key="status"
                            :value="status"
                            />
                    </el-select>
                </label>
            </div>
            
        
        </div>
        <div @click="handle_filter_data" class="calls-screen-filter-btn">
            <el-button style="width: 100%;" type="success">סנן</el-button>
        </div>
        <!-- Mobile -->
        <div v-if="platform=='mobile'" class="calls-screen-filter-content">
            <h2 v-if="!filters_data.length">לא קיימות רשומות!</h2>
            <template v-for="call in filters_data" :key="call.uid">
                <table style="margin-bottom:5px;">
                    <tr>
                        <th style="width:30%;">תאריך</th>
                        <td>{{new Date(call.created_at.seconds * 1000).toLocaleString('he')}}</td>
                    </tr>
                    <tr>
                        <th style="width:30%;">סניף</th>
                        <td>{{call.branche}}</td>
                    </tr>
                    <tr>
                        <th style="width:30%;">סטטוס</th>
                        <td><span :data-status="call.status">{{call.status}}</span></td>
                    </tr>
                    <tr>
                        <th style="width:30%;">סוג</th>
                        <td>{{call.error_type}}</td>
                    </tr>
                    <tr>
                        <th style="width:30%;">תיאור תקלה</th>
                        <td>{{call.description}}</td>
                    </tr>
                    <tr v-if="role >= 99">
                        <th style="width:30%;">פעולות</th>
                        <td>
                            <i @click="selected_call_for_info = JSON.parse(JSON.stringify(call));" class="material-icons info-icon">info</i>
                        </td>
                    </tr>
                </table>
            </template>
        </div>
        <!-- Pc -->
        <div v-else class="calls-screen-filter-content">  
            <table>
                <tr>
                    <th>תאריך</th>
                    <th>סניף</th>
                    <th>סטטוס</th>
                    <th>סוג</th>
                    <th>תיאור התקלה</th>
                    <th v-if="role >= 99">פעולות</th>
                </tr>
                <h2 v-if="!filters_data.length">לא קיימות רשומות!</h2>
                <template v-for="call in filters_data" :key="call.uid">
                    <tr>
                        <td>{{new Date(call.created_at.seconds * 1000).toLocaleString('he')}}</td>
                        <td>{{call.branche}}</td>
                        <td><span :data-status="call.status">{{call.status}}</span></td>
                        <td>{{call.error_type}}</td>
                        <td>{{call.description}}</td>
                        <td v-if="role >= 99"><i @click="selected_call_for_info = JSON.parse(JSON.stringify(call));" class="material-icons info-icon">info</i></td>
                    </tr>
                </template>
            </table>
        </div>
        <CallInfoModal
            v-if="selected_call_for_info" 
            :data="selected_call_for_info"
            @close="selected_call_for_info=null"
            @change_status="handle_change_status"
         />
    </div>
</template>

<script>
import { computed, defineAsyncComponent, ref } from 'vue'
import {get_branches_list_names,update_call_status_in_db} from '../../scripts/scripts'
import {get_errors_from_db} from '../../../../../Methods/rosman_shivuk_funcs'
import {slide_pop_error} from '../../../../../Methods/Msgs'
import {get_calls_by_filters_data} from '../../scripts/scripts'
import {user,role} from '../.././../../../Methods/Utils'
import store from '../../../../../store'

export default {
    components:{
        CallInfoModal:defineAsyncComponent(() => import('./CallInfoModal.vue'))
    },
    setup () {
        
        const is_panding = ref(false)

        const selected_call_for_info = ref(null)

        const filters_data = ref([])

        const branches_options = ref([])
        const errors_options = ref([])

        const filters = ref({
            selected_branche:'הכל',
            selected_error:'הכל',
            selected_from_date:'',
            selected_to_date:'',
            selected_status:'הכל'
        })

        
        const platform = computed(() => {
            return store.state.platform
        })

        const handle_filter_data = async() => {
            try{
                is_panding.value = true
                filters_data.value = await get_calls_by_filters_data(filters.value)
                is_panding.value = false
            }catch(err){
                is_panding.value = false
                console.error(err.message);
                slide_pop_error(err.message)
            }
        }

        const init = async() => {
            try{
                is_panding.value = true
                if(!branches_options.value.length){
                    const branches_list = await get_branches_list_names()
                    branches_options.value = ['הכל',...branches_list]
                }
                if(!errors_options.value.length){
                    const res = await get_errors_from_db()
                    errors_options.value =  ['הכל',...res.map(err => err.name)]
                }
                if(user.value.branche && role.value < 99){
                    filters.value.selected_branche = user.value.branche
                }
                is_panding.value = false
            }catch(err){
                is_panding.value = false
                console.error(err.message);
                slide_pop_error(err.message)
            }

        }

        const handle_change_status = (new_data) => {
            const index = filters_data.value.findIndex(call => call.uid == new_data.uid)
            if(index != -1){
                update_call_status_in_db(new_data)
                filters_data.value[index].status = new_data.status
            }
            selected_call_for_info.value = null
        }

        init()

        return {
            handle_filter_data,
            handle_change_status,
            branches_options,
            errors_options,
            filters,
            filters_data,
            platform,
            selected_call_for_info,
            user,
            role
        }
    }
}
</script>

<style scoped>
    .calls-screen{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
    }
    .calls-screen-title{
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        
    }
    .calls-screen-filters{
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3,1fr);
        /* grid-auto-rows: 50px; */
        grid-gap: 3px;
      
    }
    .calls-screen-filter-btn{
        width: 100%;
        padding: 5px;
    }
    .calls-screen-filter-content{
        width: 100%;
        flex: 1;
        overflow-y: auto;
        padding: 0 5px;
    }
    .calls-screen-filters > .input{
        padding: 0 3px;
    }

    table {
        border-collapse: collapse;
        width: 100%;
    }
    table td, table th {
        border: 1px solid #ddd;
        padding: 2px;       
    }
    table tr:nth-child(even){background-color: #f2f2f2;}
    table tr:nth-child(odd){background-color: #e7e6e6;}
    table tr:hover {background-color: #ddd;}
    table td{
        text-align: center;
        color: var(--main);
        font-size: 18px;
    }
    table th {
        padding-top: 2px;
        padding-bottom: 2px;
        text-align: center;
        background-color: var(--purple);
        color: white;
        position: sticky;
        top: 0;
        font-size: 20px;
    }

    .info-icon{
        color: var(--warning);
        user-select: none;
        cursor: pointer;
    }

    [data-status="סגור"],
    [data-status="בוטל"] {
        color: var(--danger) !important;
    }
    [data-status="פתוח"]{
        color: var(--success) !important;
    }
    [data-status="בטיפול"]{
        color: var(--warning) !important;
    }
</style>